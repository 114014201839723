.filter{
     height:700px;
     width: 260px;
     margin-top: 10%;
     margin-left: 4%;
     margin-bottom: 5%;
     border: 5px solid #95744c;
     box-shadow: -42px -40px 40px 5px #5c30d629;
     background-color: #e5dabf;
}

#filt{
     font-size: 35px;
     font-family: 'Courier New';
     font-weight: bold;
     text-align: center;
     border-color: black;
     border-width: 2px;
     border-style: groove;
     margin-top: 5%;
}

#listing{
     margin-top: 5%;
     margin-left: 4%;
     border-radius: 20px;
}

.listbox{
     width: 100%;
     height: 290px;
     background-color: #e5dabf;
     box-shadow: 62px 30px 40px 5px #5c30d629;
     margin-bottom: 20px;
     border-radius: 40px;
}

.bimage{
     width: 100%;
     height: 260px;
     border-radius: 23px;
     margin-top: 3%;
     margin-left:5%;
}

.bname{
     font-family: Lucida Sans Regular;
     font-size: 25px;
     font-weight: 600;
     line-height: 2;
     text-align: left;
     color: #243a08;
     margin-top: 4%;
     margin-left: 3%;
}

.bcity{
     width: 100%;
     height: 23px;
     font-family: Lucida Sans Regular;
     font-size: 14px;
     font-weight: 550;
     line-height: 3;
     text-align: left;
     color: #4d5409;
     margin-bottom: 1%;
}

.bcost{
     width: 100%;
     height: 23px;
     font-family: Lucida Sans Regular;
     font-size: 24px;
     font-weight: 550;
     text-align: left;
     color: #4d5409;
     margin-left: 5%;
     margin-bottom: 1%;
}

.bcat{
     line-height: 1;
     margin-top: 5%;
     color: #391b63;
}

.mylink{
     text-decoration: none;
}

#loade{
     text-align: center;
}

#nofa{
     text-align: center;
     font-size: 40px;
     font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}