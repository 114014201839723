.orderItems{
     float: left;
     height: 330px;
     width: 250px;
     margin: 2%;
     box-shadow: 10px 10px 20px #ded5a3;
     border-color: black;
     border-width: 2px;
     border-style: ridge;
     padding: 8px;
}

.orderItems>img{
     height: 200px;
     width: 100%;
}

.orderItems>#mybname{
     height: 50px;
     text-align: center;
}

.orderItems>#mybprice{
     height: 50px;
     font-size: 28px;
     margin-top: 10%;
     float: right;
}

.mybt{
     margin: 2%;
     float: right;
}

#mytotal{
     text-align: center;
     margin: 2%;
}

#mybname{
     font-size: 22px;
     font-weight: 400;
     font-family: Georgia, 'Times New Roman', Times, serif;
}

#mybprice{
     font-size: 20px;
}

#myresth{
     text-align: center;
     font-size: 30px;
     font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

#logf{
     text-align: center;
     font-size: 40px;
     font-family: 'Arial Narrow Bold';
     font-weight: bold;
     margin: 5%;
}

#orderhb{
     font-size: 40px;
     font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
     text-align: center;
     margin-top: 2%;
     margin-bottom: 1%;
}

.mytab{
     font-size: 18px;
     border-color: #493e02;
     border-width: 3px;
     border-style: solid;
}

.tabhe{
     border-color: #583705;
     border-width: 2px;
     border-style: solid;
}

.tabbo{
     border-right-color: #666b07;
     border-right-width: 2px;
     border-right-style: solid;
}