#reghe{
     text-align: center;
     font-size: 36px;
     font-family: 'Arial Narrow Bold';
     margin-top: 2%;
}

#regboa{
     color: #05302e;
     font-size: 20px;
     font-family: Arial, Helvetica, sans-serif;
     font-weight: bold;
     margin-top: 3%;
}

.regbo{
     margin-bottom: 2%;
     margin-bottom: 2%;
}

.regbu{
     float: right;
     font-weight: bold;
     color: #e3fbe5;
     background-color: #011801;
     margin-top: 2%;
     margin-bottom: 5%;
}

.regbu:hover{
     color: #e3fbe5;
     background-color: #011801;
}